import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    try {
      const { collapsed } = JSON.parse(localStorage.menuState || '{}');
      if (collapsed) this._toggle();
    } catch (e) {
      const collapsed = localStorage.menuState === 'collapsed';
      localStorage.menuState = JSON.stringify({ collapsed });
      if (collapsed) this._toggle();
    }
  }

  toggleMenu(ev) {
    ev.preventDefault();
    this._toggle();
  }

  _toggle() {
    this.containerTarget.classList.toggle('expanded');
    const collapsed = this.containerTarget.classList.toggle('collapsed');
    localStorage.menuState = JSON.stringify({ collapsed });
  }
}
