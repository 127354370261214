import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputContainer'];

  connect() {
    this.input = this.inputContainerTarget.querySelector('input');
    this.hiddenInput = document.createElement('span');
    this.hiddenInput.classList = this.input.classList;
    this.hiddenInput.classList.add('hidden');
    this.element.appendChild(this.hiddenInput);
    const actionValue = this.input.getAttribute('data-action') || '';
    this.input.setAttribute('data-action', `keyup->autosize-input-component#resize ${actionValue}`.trim());
    this.resize();
  }

  resize() {
    this.hiddenInput.textContent = this.input.value;
    this.hiddenInput.classList.remove('hidden');
    this.hiddenInput.classList.add('invisible');
    this.input.style.width = this.hiddenInput.offsetWidth + 36 + 'px';
    this.hiddenInput.classList.remove('invisible');
    this.hiddenInput.classList.add('hidden');
  }
}
