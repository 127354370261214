import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.containerTarget.addEventListener('scroll', this.update.bind(this));
    this.containerTarget.scrollTop = localStorage.scrollPosition || 0;
  }

  update() {
    localStorage.scrollPosition = this.containerTarget.scrollTop;
  }
}
