import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';

export default class extends Controller {
  static targets = ['receiver'];
  static values = {
    url: String,
    method: {
      type: String,
      default: 'get',
    },
  };

  fetch(ev) {
    let targets = [];
    this.receiverTargets.forEach(target => targets.push(target.id));
    const target = this.hasReceiverTarget ? this.receiverTarget.id : null;
    const value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
    const request = new FetchRequest(this.methodValue, `${this.urlValue}`, {
      responseKind: 'turbo-stream',
      query: { targets, target, id: value, value },
    });
    request.perform();
  }

  debounceFetch(ev) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.fetch(ev);
    }, 500);
  }
}
