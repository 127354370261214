import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['statusContainer'];

  connect() {
    document.addEventListener('autosave:start', () => {
      this.statusContainerTarget.textContent = 'Saving...';
    });
    document.addEventListener('autosave:success', () => {
      this.statusContainerTarget.textContent = 'Saved';
    });
    document.addEventListener('autosave:error', () => {
      this.statusContainerTarget.textContent = 'Error';
    });
  }
}
