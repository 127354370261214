import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.inputTarget.value = tz;
  }
}
