import Controller from '../../javascript/controllers/outside_click_handler_controller';

export default class extends Controller {
  static targets = ['displayInput', 'list', 'inputContainer', 'allCheckbox', 'allItems'];

  initialize() {
    this.#initializeElement();
  }

  connect() {
    super.connect();

    this.input = this.inputContainerTarget.querySelector('input');
    this.#setDisplayValues();
    this.element.addEventListener('reflex:morph', () => {
      this.#initializeElement();
      this.#setDisplayValues();
      if (this.isOpen) this.open();
    });
  }

  select(ev) {
    if (ev.target.value === 'all') {
      this.allItemsTargets.forEach(item => {
        item.checked = ev.target.checked;
      });
    } else if (this.allCheckboxTarget.checked) {
      this.allCheckboxTarget.checked = false;
      this.allItemsTargets.forEach(item => {
        item.checked = item === ev.target;
      });
    }

    this.#setDisplayValues();
    this.input.dispatchEvent(new Event('change'));
  }

  open() {
    if (!this.hasListTarget) return;

    this.listTarget.classList.remove('hidden');
    this.isOpen = true;
  }

  close() {
    if (!this.hasListTarget) return;

    this.listTarget.classList.add('hidden');
    this.isOpen = false;
  }

  #setDisplayValues = () => {
    if (!this.hasDisplayInputTarget) return;

    const selectedItems = this.allItemsTargets.filter(item => item.checked).map(item => item.dataset.text);
    this.displayInputTarget.value = selectedItems.join(', ');
  };

  #initializeElement() {
    const input = this.inputContainerTarget.querySelector('input');
    const selectedValues = input.value.split(' ').filter(Boolean);
    const reflexTarget = input.dataset.reflexTarget;

    input.removeAttribute('data-reflex-target');
    this.displayInputTarget.setAttribute('id', input.id);

    this.allItemsTargets.forEach(checkbox => {
      checkbox.setAttribute('name', `${input.name}[]`);

      if (selectedValues.includes(checkbox.value)) {
        checkbox.checked = true;
      }
      if (reflexTarget) {
        checkbox.setAttribute('data-reflex-target', reflexTarget);
      }
    });
    input.removeAttribute('name');
    this.element.addEventListener('keydown', event => {
      if (event.key === 'Tab') {
        this.close();
        this.input.dispatchEvent(new Event('change'));
      }
    });
  }
}
