import Controller from '../../javascript/controllers/outside_click_handler_controller';

export default class extends Controller {
  static targets = ['menu'];

  toggle() {
    if (this.menuTarget.classList.contains('hidden')) {
      this.open();
    } else {
      this.close();
    }
  }

  close() {
    this.menuTarget.classList.add('hidden');
    this.menuTarget.classList.remove('block');

    const ev = new CustomEvent('popover:close');
    this.menuTarget.dispatchEvent(ev);
  }

  open() {
    this.menuTarget.classList.remove('hidden');
    this.menuTarget.classList.add('block');

    const ev = new CustomEvent('popover:open');
    this.menuTarget.dispatchEvent(ev);
  }
}
