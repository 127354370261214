import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'content', 'trigger'];

  connect() {
    document.addEventListener('click', e => {
      const isTrigger = this.triggerTarget.contains(e.target) || this.triggerTarget === e.target;
      const isContainer = this.containerTarget.contains(e.target) || this.containerTarget === e.target;
      if (this._isOpen() && !isTrigger && !isContainer) {
        e.preventDefault();
        this.toggle();
      }
    });
  }

  toggle() {
    this.containerTarget.classList.toggle('-translate-x-full');
    this.contentTarget.classList.toggle('pointer-events-none');
  }

  _isOpen() {
    return !this.containerTarget.classList.contains('-translate-x-full');
  }
}
