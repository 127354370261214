import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  focus() {
    this.inputTarget.focus();
    this.inputTarget.selectionStart = this.inputTarget.selectionEnd = this.inputTarget.value.length;
  }
}
