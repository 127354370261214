import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'filenames'];

  select() {
    this.inputTarget.click();
  }

  fileSelected(ev) {
    const filenames = [];
    for (const file of ev.target.files) {
      filenames.push(file.name);
    }
    const filenamesString = filenames.length > 0 ? filenames.join(', ') : 'No file chosen';

    this.filenamesTarget.textContent = filenamesString;
  }
}
