import { Controller } from '@hotwired/stimulus';
import { destroy } from '@rails/request.js';

export default class extends Controller {
  static targets = ['scope', 'allInput', 'itemInput', 'toggleElement'];
  static classes = ['elementSelected', 'noElementSelected'];
  static values = { url: String };

  get #selectedItems() {
    return this.itemInputTargets.filter(input => input.checked);
  }

  get #formData() {
    const formData = new FormData();
    this.#selectedItems.forEach(input => formData.append('ids[]', input.value));

    return formData;
  }

  toggleAll(ev) {
    const newState = ev.target.checked;

    this.itemInputTargets.forEach(input => (input.checked = newState));
    this.#toggleClasses(newState);
  }

  itemChanged() {
    if (this.allInputTarget.checked) {
      this.allInputTarget.checked = false;
    } else if (this.#selectedItems.length === this.itemInputTargets.length) {
      this.allInputTarget.checked = true;
    }

    this.#toggleClasses(this.#selectedItems.length > 0);
  }

  submit(ev) {
    ev.preventDefault();

    if (this.#selectedItems.length > 0) {
      const query = new URLSearchParams(this.#formData).toString();

      window.Turbo.visit(`${this.urlValue}?${query}`);
    }
  }

  destroy(ev) {
    ev.preventDefault();

    if (this.#selectedItems.length > 0) {
      destroy(ev.target.href, {
        body: this.#formData,
        responseKind: 'turbo-stream',
      });
    }
  }

  #toggleClasses(selected) {
    if (this.#shouldToggleClasses()) {
      if (selected) {
        this.toggleElementTarget.classList.remove(this.noElementSelectedClass);
        this.toggleElementTarget.classList.add(this.elementSelectedClass);
      } else {
        this.toggleElementTarget.classList.add(this.noElementSelectedClass);
        this.toggleElementTarget.classList.remove(this.elementSelectedClass);
      }
    }
  }

  #shouldToggleClasses() {
    return this.hasToggleElementTarget && this.hasElementSelectedClass && this.hasNoElementSelectedClass;
  }
}
