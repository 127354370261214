import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'text', 'arrow'];

  open() {
    this.textTarget.classList.remove('text-brand-teal-normal');
    this.textTarget.classList.add('text-white');
    this.buttonTarget.classList.remove('bg-transparent');
    this.buttonTarget.classList.add('bg-brand-teal-normal');
    this.arrowTarget.classList.add('stroke-white');
    this.arrowTarget.classList.remove('stroke-brand-teal-normal');
  }

  close() {
    this.textTarget.classList.add('text-brand-teal-normal');
    this.textTarget.classList.remove('text-white');
    this.buttonTarget.classList.add('bg-transparent');
    this.buttonTarget.classList.remove('bg-brand-teal-normal');
    this.arrowTarget.classList.remove('stroke-white');
    this.arrowTarget.classList.add('stroke-brand-teal-normal');
  }
}
