import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';
import { Idiomorph } from 'idiomorph/dist/idiomorph.esm.js';

export default class extends Controller {
  static targets = ['serializable', 'replace'];
  static values = {
    callable: String,
    action: String,
    broadcastTo: String,
    debounce: { type: Number, default: 0 },
  };

  connect() {
    this.consumer = consumer.subscriptions.create(
      { channel: 'ReflexChannel', room: this.#roomNumber },
      { received: this.#process.bind(this) }
    );
    document.addEventListener('reflex:broadcast', this.#receiveBroadcast.bind(this));
  }

  disconnect() {
    this.consumer.unsubscribe();
  }

  get params() {
    const formData = new FormData();
    const allSerializableTargets = this.element.querySelectorAll('[data-reflex-target="serializable"]');

    allSerializableTargets.forEach(target => {
      if (target.type === 'checkbox') {
        if (target.checked) formData.append(target.name, target.value);
      } else {
        formData.append(target.name, target.value);
      }
    });

    if (window.debugReflex) {
      const debugParams = { ...Object.fromEntries(formData.entries()) };
      console.info('%cReflexController#perform', 'color:lightgreen', debugParams);
    }

    return new URLSearchParams(formData).toString();
  }

  perform(event) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.consumer.perform('process', {
        params: this.params,
        callable: this.callableValue,
        element: this.#parseTarget(event?.target),
      });
    }, this.debounceValue);
  }

  broadcastEvent() {
    const event = new CustomEvent('reflex:broadcast', { detail: { formObject: this.broadcastToValue } });
    document.dispatchEvent(event);
  }

  #receiveBroadcast(event) {
    if (event.detail.formObject === this.callableValue) this.perform();
  }

  #process(data) {
    const html = data.html.trim();

    switch (this.actionValue) {
      case 'replace_partial':
        this.#replace_partial(html);
        break;
      case 'replace':
        this.#replace(html);
        break;
      case 'null':
        break;
      default:
        console.warn(`ReflexController: Unsupported action: ${this.actionValue}`);
    }

    if (this.hasBroadcastToValue) this.broadcastEvent();
  }

  #replace_partial(html) {
    const template = document.createElement('template');
    template.innerHTML = html;
    const element = template.content.firstChild;
    const partials = this.element.querySelectorAll("[data-reflex-target='partial']");
    const newPartials = element.querySelectorAll("[data-reflex-target='partial']");

    for (const newPartial of newPartials) {
      const reflexTargetId = newPartial.dataset.reflexTargetId;
      for (const partial of partials) {
        if (partial.dataset.reflexTargetId === reflexTargetId) {
          this.#morph(partial, newPartial);
          break;
        }
      }
    }
  }

  #replace(html) {
    const template = document.createElement('template');
    template.innerHTML = html;
    const element = template.content.firstChild;

    if (this.hasReplaceTarget) {
      this.#morph(this.replaceTarget, element);
    } else {
      this.#morph(this.element, element);
    }
  }

  #morph(currentElement, newElement) {
    Idiomorph.morph(currentElement, newElement, {
      callbacks: {
        afterNodeMorphed: node => {
          node.dispatchEvent(new Event('reflex:morph'));
        },
      },
    });
  }

  #parseTarget(target) {
    if (!target) return {};

    return {
      id: target.id,
      name: target.name,
      value: target.value,
      type: target.type,
      tagName: target.tagName,
      dataset: target.dataset,
    };
  }

  get #roomNumber() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }
}
