import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['frame', 'source'];
  static values = { url: String };

  fetch(ev) {
    const link = document.createElement('a');

    this.frameTarget.setAttribute('id', this.sourceTarget.id);
    link.href = `${this.urlValue}?frame_id=${this.sourceTarget.id}&value=${ev.target.value}`;
    this.frameTarget.appendChild(link);
    link.click();
    link.remove();
  }

  debounceFetch(ev) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.fetch(ev);
    }, 500);
  }
}
