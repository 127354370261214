import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-start', () => {
      const ev = new CustomEvent('autosave:start');
      document.dispatchEvent(ev);
    });
    this.element.addEventListener('turbo:submit-end', ev => {
      if (ev.detail.success) {
        const ev = new CustomEvent('autosave:success');
        document.dispatchEvent(ev);
      } else {
        const ev = new CustomEvent('autosave:error');
        document.dispatchEvent(ev);
      }
    });
  }

  submit() {
    this.element.requestSubmit();
  }

  debounceSubmit() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.submit();
    }, 500);
  }
}
