import Controller from '../../javascript/controllers/outside_click_handler_controller';

export default class extends Controller {
  static targets = ['inputContainer', 'list', 'search', 'error', 'noResults', 'hint'];

  connect() {
    super.connect();

    this.currentOptionIndex = -1;
    this.isOpen = false;

    this.#initializeElement();
    this.element.addEventListener('reflex:morph', () => {
      this.#initializeElement();
      if (this.isOpen) this.open();
    });
  }

  get selectedClass() {
    return '!bg-brand-blue-light-hover';
  }

  get options() {
    return this.listTarget.querySelectorAll('[role="option"]');
  }

  highlightCurrentOption() {
    const currentOption = this.options[this.currentOptionIndex];
    if (!currentOption) return;

    currentOption.classList.add(this.selectedClass);
    currentOption.scrollIntoView({ block: 'nearest' });

    this.options.forEach(option => {
      if (option !== currentOption) {
        option.classList.remove(this.selectedClass);
      }
    });
  }

  moveHightlightDown() {
    if (this.currentOptionIndex < this.options.length - 1) {
      this.currentOptionIndex++;
    } else {
      this.currentOptionIndex = 0;
    }
    this.highlightCurrentOption();
  }

  moveHightlightUp() {
    if (this.currentOptionIndex > 0) {
      this.currentOptionIndex--;
    } else {
      this.currentOptionIndex = this.options.length - 1;
    }
    this.highlightCurrentOption();
  }

  allowSelection(event) {
    if (event.key === 'Enter' && this.isOpen) {
      event.preventDefault();
    }
  }

  filterItems(query) {
    let filteredItems = [];
    let currentSection = [];
    const minimum = this.allItems.some(li => li.dataset.header === 'true') ? 2 : 1;

    this.allItems.forEach(li => {
      if (li.dataset.header === 'true') {
        if (currentSection.length >= minimum) {
          filteredItems = filteredItems.concat(currentSection);
        }
        currentSection = [li];
      } else if (li.innerText.trim().toLowerCase().includes(query.toLowerCase())) {
        currentSection.push(li);
      }
    });

    if (currentSection.length >= minimum) {
      filteredItems = filteredItems.concat(currentSection);
    }

    if (filteredItems.length === 0) {
      this.listTarget.replaceChildren(this.noResultsTarget.content.cloneNode(true));
    } else {
      this.listTarget.replaceChildren(...filteredItems);
    }
  }

  filter(event) {
    const { key } = event;

    if (['Shift', 'Tab'].includes(key)) return;

    const selectionKeys = ['ArrowDown', 'ArrowUp'];

    if (selectionKeys.includes(key)) {
      if (key === 'ArrowDown') {
        this.moveHightlightDown();
      } else {
        this.moveHightlightUp();
      }
      return;
    } else if (key === 'Enter' && this.isOpen) {
      event.preventDefault();

      const target = this.options[this.currentOptionIndex];
      if (target) {
        this.select({ target });
        this.close();
      }
      return;
    }

    this.currentOptionIndex = -1;
    this.options.forEach(option => {
      option.classList.remove(this.selectedClass);
    });
    const query = event.target.value;

    this.filterItems(query);
  }

  setValue(text) {
    this.searchTarget.value = text;
    this.hintTarget.setAttribute('data-hint', text);
  }

  select(ev) {
    const item = ev.target;
    const text = item.querySelector('span[data-selected-value]').innerText;

    this.input.value = item.dataset.value;
    this.setValue(text.trim());
    this.input.dispatchEvent(new Event('change'));
    this.close();
  }

  open() {
    this.listTarget.classList.remove('hidden');
    this.isOpen = true;
  }

  close() {
    this.listTarget.classList.add('hidden');
    this.isOpen = false;

    if (this.input.value === '') {
      this.setValue('');
      this.filter({ target: { value: '' } });
    }
  }

  toggle() {
    if (this.listTarget.classList.contains('hidden')) {
      this.open();
    } else {
      this.close();
    }
  }

  #initializeElement() {
    this.input = this.inputContainerTarget.querySelector('input');
    this.domId = Date.now();
    this.listTarget.setAttribute('id', this.domId);
    this.allItems = Array.from(this.element.querySelectorAll('li'));
    this.searchTarget.setAttribute('id', this.input.name);

    this.element.querySelectorAll('li[data-value]').forEach(li => {
      if (li.dataset.value === this.input.value) {
        const text = li.querySelector('span[data-selected-value]').innerText;
        this.setValue(text.trim());
      }
    });

    const error = this.element.querySelector('div.hidden > span.error');
    if (error) {
      this.errorTarget.innerHTML = error.innerHTML;
    }

    this.filter({ target: { value: '' } });
    this.element.addEventListener('keydown', event => {
      if (event.key === 'Tab') {
        this.close();
        this.input.dispatchEvent(new Event('change'));
      }
    });
  }
}
