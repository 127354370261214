import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'association', 'template'];
  static values = { selectors: Object, scope: String };

  connect() {
    const { container } = this.selectorsValue;

    this.element.querySelector(container).setAttribute('data-form-has-many-component-target', 'container');
    this.#setDataAttributes(this.element);

    this.associationTargets.forEach(node => {
      this.#setItemKeys(node);
    });
  }

  append(ev) {
    ev.preventDefault();

    const id = Math.floor(Math.random() * 10000000);
    const newNode = this.templateTarget.content.firstElementChild.cloneNode(true);

    newNode.setAttribute('data-form-has-many-component-target', 'association');
    newNode.setAttribute('id', `${this.scopeValue}_${id}`);
    this.#setItemKeys(newNode, id);
    this.containerTarget.appendChild(newNode);
  }

  remove(ev) {
    ev.preventDefault();

    const node = this.associationTargets.find(node => node.contains(ev.target));
    const checkbox = node.querySelector('input[type="hidden"][name*="_destroy"]');
    const idInput = node.querySelector('input[type="hidden"][name*="id"]');

    if (!idInput) {
      console.warn(
        `Hidden 'id' input not found for association. Please ensure that the associations are rendered with a hidden 'id' field.`
      );
      return;
    }

    if (idInput.value) {
      checkbox.value = 1;
      node.style.display = 'none';
    } else {
      node.remove();
    }
  }

  #setDataAttributes(element) {
    const { item } = this.selectorsValue;

    element.querySelectorAll(item).forEach(node => {
      node.setAttribute('data-form-has-many-component-target', 'association');
    });
  }

  #setItemKeys(node, id = null) {
    const inputs = node.querySelectorAll('input, select, textarea');
    const uuid = id || Math.floor(Math.random() * 10000000);
    const domId = node.getAttribute('id');

    if (domId) {
      const domIdMatch = domId.match(/new_(.*)/);
      if (domIdMatch) node.setAttribute('id', `${domIdMatch[1]}_${uuid}`);
    }

    inputs.forEach(input => {
      const name = input.getAttribute('name');
      const id = input.getAttribute('id');

      if (name) {
        input.setAttribute('name', name.replace(/\[\]/, `[${uuid}]`));
      }
      if (id) {
        input.setAttribute('id', `${id}__${uuid}`);
      }
    });

    return uuid;
  }
}
