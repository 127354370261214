import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  connect() {
    if (this.hasCheckboxTarget) {
      this.valueInput = this.checkboxTarget.previousElementSibling;
      if (this.checkboxTarget.dataset.autosubmit === 'true') {
        this.valueInput.setAttribute('data-action', 'change->autosubmit#submit');
      }
    }
  }

  remove(ev) {
    ev.preventDefault();

    const idInput = this.element.querySelector('input[type="hidden"][name*="id"]');

    if (!idInput) {
      console.warn(
        `Hidden 'id' input not found for association. Please ensure that the associations are rendered with a hidden 'id' field.`
      );
      return;
    }

    if (idInput.value && this.hasCheckboxTarget) {
      this.valueInput.value = 1;
      this.valueInput.dispatchEvent(new Event('change'));
      this.checkboxTarget.checked = true;
      this.element.style.display = 'none';
    } else {
      this.element.remove();
    }

    const additionalRowsToRemove = document.querySelectorAll(`tr[data-remove-with-id="${this.element.id}"]`);
    additionalRowsToRemove.forEach(row => row.remove());
  }
}
