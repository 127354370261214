import * as Sentry from '@sentry/browser';

// eslint-disable-next-line no-undef
const dsn = ['production', 'staging'].includes(NODE_ENV)
  ? 'https://e8a26c04b7dd4a56b7dd4213cbefa52c@o1139896.ingest.sentry.io/6196550'
  : '';

Sentry.init({
  dsn,
  release: REVISION, // eslint-disable-line no-undef
  environment: NODE_ENV, // eslint-disable-line no-undef
  sampleRate: 1.0,
  tracesSampleRate: 0,
  autoSessionTracking: true,
  denyUrls: [/zdassets/, /announcekit/],
  ignoreErrors: [
    "Failed to execute 'removeChild' on 'Node'",
    'The object can not be found here',
    'Failed to fetch',
    'Fetch is aborted',
    'fullcalendar',
    'Operation aborted',
  ],
});
