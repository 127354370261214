import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    this.element.querySelectorAll('input[type="radio"]').forEach(radio => {
      radio.addEventListener('change', ev => {
        this.element
          .querySelectorAll(`input[type="radio"]:not([name="${ev.target.name}"])`)
          .forEach(radio => (radio.checked = false));
        ev.target.checked = true;
        ev.target.dispatchEvent(new Event('update'));
      });
    });
  }
}
