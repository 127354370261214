import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import { mergeDeepRight } from 'ramda';

export default class extends Controller {
  static targets = ['canvas'];
  static values = {
    type: {
      type: String,
      default: 'bar',
    },
    data: Object,
    options: Object,
  };

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;

    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions,
    });
  }

  disconnect() {
    this.chart.destroy();
    this.chart = undefined;
  }

  get chartData() {
    if (!this.hasDataValue) {
      console.warn('No data provided for chart');
    }

    return this.dataValue;
  }

  get chartOptions() {
    return mergeDeepRight(this.defaultOptions, this.optionsValue);
  }

  get defaultOptions() {
    return {
      defaultFontFamily: "'Inter'",
      defaultFontColor: '#00cca4',
      maintainAspectRatio: false,
      tooltipTemplate: valueObj => valueObj.value.toLocaleString(),
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) label += ': ';
            label += tooltipItem.yLabel.toLocaleString();
            return label;
          },
        },
      },
      scales: {
        xAxis: {
          title: {
            color: '#00997B',
            font: {
              family: "'Inter'",
              size: 18,
            },
            display: true,
          },
          ticks: {
            stepSize: 1,
            min: 0,
            autoSkip: false,
          },
          grid: {
            display: false,
          },
        },
        yAxis: {
          ticks: {
            beginAtZero: true,
          },
          title: {
            color: '#00997B',
            font: {
              family: "'Inter'",
              size: 18,
            },
            fontSize: 18,
            display: true,
          },
        },
      },
    };
  }
}
