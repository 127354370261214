import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputs'];
  static values = { items: Array, mappings: Object };

  change(ev) {
    const item = this.itemsValue.find(item => item.id === Number(ev.target.value));

    this.inputsTargets.forEach(input => {
      for (const key in this.mappingsValue) {
        if (input.name.includes(this.mappingsValue[key])) {
          input.value = item[key];
        }
      }
    });
  }
}
