import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.submitTimeout = setTimeout(() => this.element.submit(), 250);
  }

  disconnect() {
    this.clearTimeout(this.submitTimeout);
  }
}
