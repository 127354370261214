import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'message',
    'header',
    'confirmButton',
    'standardTemplate',
    'dangerTemplate',
    'templateContainer',
  ];

  connect() {
    window.Turbo.setConfirmMethod(this.open.bind(this));
  }

  open(message, _formElement, submitter) {
    return new Promise(resolve => {
      const { type, header, confirmText, formId } = submitter?.dataset || {};
      const template = this.#templates[type];
      const content = template.content.cloneNode(true);
      const alternateForm = document.querySelector(`#${formId}`);
      const handler = this.#handleClose().bind(this, resolve, alternateForm);

      this.templateContainerTarget.replaceChildren(content);
      this.messageTarget.textContent = message;
      this.confirmButtonTarget.textContent = confirmText || 'Ok';
      if (header) this.headerTarget.textContent = header;

      this.element.showModal();

      this.element.addEventListener('close', handler, { once: true });
    });
  }

  #handleClose() {
    return function (resolve, alternateForm) {
      const confirmed = this.element.returnValue == 'confirm';

      if (confirmed && alternateForm) {
        alternateForm.requestSubmit();
      } else {
        resolve(confirmed);
      }
    };
  }

  get #templates() {
    return {
      standard: this.standardTemplateTarget,
      danger: this.dangerTemplateTarget,
      undefined: this.standardTemplateTarget,
    };
  }
}
