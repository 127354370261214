import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab'];

  connect() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);

    if (this.hasTabTarget) {
      this.timeout = setTimeout(() => {
        this.interval = setInterval(() => this.#fade(), 50);
      }, 3000);
    }
  }

  remove() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);

    this.tabTarget.remove();
  }

  #fade() {
    const opacity = Number(
      window.getComputedStyle(this.tabTarget).getPropertyValue('opacity')
    );

    if (opacity > 0) {
      this.tabTarget.style.opacity = opacity - 0.1;
    } else {
      this.remove();
    }
  }
}
