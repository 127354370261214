import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'text', 'icon'];

  open() {
    this.textTarget.classList.remove('text-brand-blue-dark');
    this.textTarget.classList.add('text-white');
    this.iconTarget.classList.remove('fill-brand-teal-normal');
    this.iconTarget.classList.add('fill-white');
    this.buttonTarget.classList.remove('bg-transparent');
    this.buttonTarget.classList.add('bg-brand-teal-normal');
  }

  close() {
    this.textTarget.classList.add('$text-brand-teal-normal');
    this.textTarget.classList.remove('text-white');
    this.iconTarget.classList.remove('fill-white');
    this.iconTarget.classList.add('fill-brand-teal-normal');
    this.buttonTarget.classList.add('bg-transparent');
    this.buttonTarget.classList.remove('bg-brand-teal-normal');
  }
}
