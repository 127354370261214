import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['icon', 'content'];
  static values = {
    closedIcon: { type: String, default: '&#9658;' },
    openIcon: { type: String, default: '&#9660;' },
  };

  connect() {
    this.isOpen = false;
    const activeItems = this.element.querySelectorAll('.current');
    if (activeItems.length > 0) {
      this.isOpen = true;
      this.contentTarget.classList.remove('hidden');
      this.contentTarget.classList.add('block');
      this.iconTarget.innerHTML = this.openIconValue;
    }
  }

  toggle(ev) {
    ev.preventDefault();

    this.isOpen = !this.isOpen;
    this.contentTarget.classList.toggle('hidden');
    this.contentTarget.classList.toggle('block');

    if (this.isOpen) {
      this.iconTarget.innerHTML = this.openIconValue;
    } else {
      this.iconTarget.innerHTML = this.closedIconValue;
    }
  }
}
