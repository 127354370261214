import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('click', e => {
      if (!this.element.contains(e.target)) {
        this.close();
      }
    });

    document.addEventListener('turbo:visit', () => {
      this.close();
    });
  }
}
