import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];
  static classes = ['element'];

  toggle() {
    this.elementTargets.forEach(el => el.classList.toggle(this.elementClass));
  }

  add() {
    this.elementTargets.forEach(el => el.classList.add(this.elementClass));
  }

  remove() {
    this.elementTargets.forEach(el => el.classList.remove(this.elementClass));
  }
}
