import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab', 'content', 'nav'];
  static values = { hasClassOverrides: { type: Boolean, default: false } };
  static classes = ['active'];

  initialize() {
    this.#initializeTabs();
  }

  click(ev) {
    ev.preventDefault();

    let newActiveTab = ev.target;
    let linkFound = false;

    while (linkFound === false) {
      if (newActiveTab.tagName.toLowerCase() === 'a') {
        linkFound = true;
      } else {
        newActiveTab = newActiveTab.parentElement;
        if (newActiveTab.tagName.toLowerCase() === 'html') {
          linkFound = true;
          console.warn('Could not find link in tab');
        }
      }
    }

    this.#deactivateCurrentTab();
    this.#activateTab(newActiveTab);
  }

  #initializeTabs() {
    this.#moveTabContent();
    const tabToActivate = this.#findTabToActivate();
    const currentActiveTab = this.#findCurrentActiveTab();

    if (currentActiveTab) {
      this.activeTab = currentActiveTab;
      this.#deactivateCurrentTab();
    }
    this.activeTab = tabToActivate;
    this.#activateTab(tabToActivate);
  }

  #findTabToActivate() {
    const tab = this.#findTabSearchParam();
    const hash = window.location.hash.substring(1);
    const tabName = hash || tab;

    return this.tabTargets.find(tab => tab.dataset.name === tabName) || this.tabTargets[0];
  }

  #activateTab(tab) {
    if (this.hasClassOverridesValue) {
      for (const child of tab.children) {
        child.classList.add(...this.activeClasses);
      }
    } else {
      tab.classList.add(...this.activeClasses);
    }

    const content = this.element.querySelector(`article[id="${tab.dataset.name}"]`);

    if (content) {
      content.classList.remove('hidden');
    }
    this.activeTab = tab;
  }

  #deactivateCurrentTab() {
    if (this.hasClassOverridesValue) {
      for (const child of this.activeTab.children) {
        child.classList.remove(...this.activeClasses);
      }
    } else {
      this.activeTab.classList.remove(...this.activeClasses);
    }

    const content = this.element.querySelector(`article[id="${this.activeTab.dataset.name}"]`);

    if (content) {
      content.classList.add('hidden');
    }
  }

  #findCurrentActiveTab() {
    return this.tabTargets.find(tab => {
      if (this.hasClassOverridesValue) {
        return Array.from(tab.children).some(child => child.classList.contains(...this.activeClasses));
      } else {
        return tab.classList.contains(...this.activeClasses);
      }
    });
  }

  #findTabSearchParam() {
    const search = new URLSearchParams(window.location.search);
    return search.get('tab');
  }

  #moveTabContent() {
    const fragment = new DocumentFragment();
    const tabContents = this.navTarget.querySelectorAll(':scope > article');

    if (tabContents.length > 0) {
      fragment.append(...tabContents);

      this.contentTarget.replaceChildren(fragment);
    }
  }
}
