import tippy from 'tippy.js';

function initializeTippy() {
  tippy(document.querySelectorAll('[data-tippy-content]'));
  tippy(document.querySelectorAll('[data-tippy]'), {
    content(reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
    interactive: true,
  });
}

document.addEventListener('turbo:load', initializeTippy);
document.addEventListener('turbo:frame-render', initializeTippy);
document.addEventListener('turbo:before-stream-render', () => {
  setTimeout(initializeTippy, 100);
});
