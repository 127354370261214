import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';

export default class extends Controller {
  static targets = ['inputContainer'];
  static values = {
    url: String,
    method: String,
    namespace: String,
    responseKind: {
      default: 'turbo-stream',
      type: String,
    },
  };

  connect() {
    this.input = this.inputContainerTarget.querySelector('input, textarea');
    const actionValue = this.input.getAttribute('data-action') || '';
    this.input.setAttribute('data-action', `change->autosave-input-component#save ${actionValue}`.trim());
  }

  save() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      this.#fireStartEvent();

      let params = { [this.input.name]: this.input.value };

      if (this.namespaceValue) {
        params = { [this.namespaceValue]: params };
      }

      const request = new FetchRequest(this.methodValue, this.urlValue, {
        body: JSON.stringify(params),
        responseKind: this.responseKindValue,
      });
      const response = await request.perform();

      if (response.ok) {
        this.#fireSuccessEvent(response);
      } else {
        this.#fireErrorEvent(response);
      }
    }, 1000);
  }

  #fireStartEvent() {
    const ev = new CustomEvent('autosave:start');
    document.dispatchEvent(ev);
  }

  #fireSuccessEvent(response) {
    const ev = new CustomEvent('autosave:success', {
      detail: { response },
    });
    document.dispatchEvent(ev);
  }

  #fireErrorEvent(response) {
    const ev = new CustomEvent('autosave:error', {
      detail: { response },
    });
    document.dispatchEvent(ev);
  }
}
